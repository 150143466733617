<!--

      This component shows a recipe card. It is used in many places, such as the
      menu wizard (createplan), the current plan page, the recent orders page.

 -->

<template>
    <div class="recipe-card" @click="openModal()" v-if="planrecipe">

      <!-- This is only visible on the current plan page -->
      <div v-if="isEaten">
        <Icon name="checkmarkOutline" class="icon-active-green pos-top-right" />
      </div>

      <!-- Only show if missingCount is bigger than 0 and therefore true from a boolean perspective -->
      <div class="missing-count top-position" v-if="showAvailability && this.totalCount && this.missingCount">
        <div class="missing-red"></div>
        <div class="secondary-text-gray">nicht alles lieferbar</div>
      </div>

      <!-- Lazy loading images in 3 steps: 1) No image 2) Low quality image 3) High quality image -->
      <div class="recipe-image">
        <img v-show="!isLoadedLQ && !isLoadedHQ" class="recipe-bg-img" />
        <img v-show="isLoadedLQ && !isLoadedHQ" :src="srcLQ" @load="isLoadedLQ = 'true'" v-bind:class="[{'bw': isEaten}]" />
        <img v-show="isLoadedHQ" :src="srcHQ" @load="isLoadedHQ = 'true'" v-bind:class="[{'bw': isEaten}]" />
      </div>

      <div class="recipe-info">

        <div class="recipe-features">
          <div class="recipe-feature" v-if="planrecipe && planrecipe.recipe && planrecipe.recipe.ready_in">
            <Icon name="timeOutline" class="icon-feature"/>
            <span>{{ planrecipe.recipe.ready_in }} min insgesamt</span>
          </div>
          <!-- <div class="recipe-feature" v-if="planrecipe.recipe.total_kcal">
            <Icon name="flameOutline" class="icon-feature"/>
            <span>{{ Math.round(planrecipe.recipe.total_kcal) }} kcal</span>
          </div> -->
          <div class="recipe-like">
            <LikeButton :recipe="planrecipe && planrecipe.recipe" :showLikes="true"/>
          </div>
        </div>

        <div class="main-text" data-cy="recipetitle" v-if="planrecipe">{{ planrecipe.recipe.name }}</div>

        <div class="recipe-buttons">

          <!-- Buttons for the replace and add recipe pages -->

          <div class="btn-primary-small min-width" @click.stop="checkWhetherToAddOrReplaceRecipe()" v-if="!type && !onmenu">
            <div v-if="!selectingThisRecipe">Auswählen</div>
            <ion-spinner v-else name="crescent" class="spinner-select"></ion-spinner>
          </div>
          <div class="btn-secondary-small" @click.stop="deleteThisRecipeFromMenu()" v-if="!type && onmenu">
            Entfernen
          </div>

          <div v-if="!type" class="secondary-text-gray">
            <div v-if="!$store.state.loadingPriceDeltas && planrecipe" :key="planrecipe.recipe.price_delta">
              <Price v-if="planrecipe.recipe.price_delta != null" :price="planrecipe.recipe.price_delta" />
              <span v-if="planrecipe.recipe.price_delta > 0"> Aufpreis</span>
              <span v-if="planrecipe.recipe.price_delta <= 0"> sparen</span>
            </div>
            <div v-else>
              <ion-spinner name="crescent" class="reloading-price-delta"></ion-spinner>
            </div>
          </div>
          
          <!-- Buttons for the cooking tab -->

          <div class="btn-secondary-small" v-if="type === 'cooking'" @click.stop="markAsEaten()">
            <div v-if="!isEaten">{{ lang.de.ate_it }}</div>
            <div v-if="isEaten">{{ lang.de.not_eaten }}</div>
          </div>

          <div 
            class="btn-tertiary-small" 
            v-if="!isEaten && type === 'cooking'" 
            @click.stop="$emit('eatMealLater', { dayId: dayId, order: this.order })"
          >
            {{ lang.de.eat_later }}
          </div>

          <!-- Button to replace a dish by going to the replace page -->

          <div class="btn-secondary-small" v-if="type === 'planning' && $store.getters.oldCustomerJourney" @click.stop="goToReplace()">
            {{ lang.de.replace }}
          </div>

          <!-- Button to delete a dish -->

          <div v-if="type === 'planning'">
            <Icon v-if="!deletingThisRecipe" name="trashOutline" :class="['delete-button', { 'disabled-delete-button': $store.state.deletingRecipe }]" @click.stop="deleteThisRecipeFromMenu()" />
            <ion-spinner v-else name="crescent" class="reloading-price-delta"></ion-spinner>
          </div>

          <!-- Button to edit the serving size -->

          <AmountSelector
            v-if="type === 'planning' && planrecipe"
            class="action-btn amount-selector"
            :amount="planrecipe.serving_size"
            :update="saveDishChange"
            :increment="0.5"
            :key="planrecipe.serving_size"
          />

        </div>
      </div>
    </div>
</template>

<script>
import Price from '@/components/Price.vue'
import AmountSelector from '@/components/AmountSelector.vue'
import LikeButton from '@/components/buttons/LikeButton'
import { constant } from '@/localization'
import { IonSpinner } from '@ionic/vue'

export default {
  name: 'recipecard',
  props: ['order', 'rplan', 'recipe', 'type', 'replaceindex', 'dayId', 'showAvailability'],
  components: {
    Price,
    LikeButton,
    AmountSelector,
    IonSpinner
  },
  data () {
    return {
      isLoadedLQ: false,
      isLoadedHQ: false,
      lang: constant,
      deletingThisRecipe: null,
      selectingThisRecipe: null
    }
  },
  computed: {
    availabilityData () {
      if (this.planrecipe && this.$store.state.recipeAvailability) {
        const id = this.planrecipe.recipe.id
        return this.$store.state.recipeAvailability.find(d => d.recipe === id)
      }
    },
    totalCount () {
      if (this.availabilityData) {
        return this.availabilityData.totalCount
      }
    },
    missingCount () {
      if (this.availabilityData) {
        return this.availabilityData.missingCount
      }
    },
    srcHQ () {
      if (this.planrecipe) return process.env.VUE_APP_IMAGE_CDN + process.env.VUE_APP_IMAGE_FILTER2 + this.planrecipe.recipe.main_img
    },
    srcLQ () {
      if (this.planrecipe) return process.env.VUE_APP_IMAGE_CDN + process.env.VUE_APP_IMAGE_FILTER2 + process.env.VUE_APP_IMAGE_LQIP + this.planrecipe.recipe.main_img
    },
    isEaten () {
      if (this.planrecipe && this.planrecipe.hasOwnProperty('eaten')) return this.planrecipe.eaten && this.type === 'cooking'
    },
    onmenu () {
      if (this.$store.state.newplan && this.$store.state.newplan.single_days) {
        const days = this.$store.state.newplan.single_days
        const foundDay = days.some(day => day.planrecipes.some(p => p.recipe.id === this.planrecipe.recipe.id))
        return foundDay
      }
      return false
    },
    planrecipe () {
      if (this.recipe) {
        return { recipe: this.recipe }
      } else if (this.rplan) {
        return this.rplan
      }
    }
  },
  methods: {
    openModal() {
      this.$store.dispatch('openModal', { name: 'modalrecipedetails' })
      this.$store.dispatch('save', { key: 'modalShowAvailability', value: this.showAvailability })
      if (this.order) {
        this.$store.dispatch('save', { key: 'modalOrder', value: this.order })
      } else {
        this.$store.dispatch('save', { key: 'modalOrder', value: null })
      }
      this.$store.dispatch('save', { key: 'modalrecipe', value: this.planrecipe.recipe })
      this.$store.dispatch('save', { key: 'modalrplan', value: this.planrecipe })
    },
    saveDishChange ( servings ) {
      if (servings > 0) {
        // To make the app respond faster, we first take the assumption that the
        // BE call will succeed.
        this.planrecipe.serving_size = servings
        // Now we call the api.
        const details = {
          url: this.$store.state.api.plan.rp,
          id: this.planrecipe.id,
          method: 'PATCH',
          data: {
              serving_size: servings
          }
        }
        this.$store.dispatch('apiSend', details).then(result => {
          if (result) {
            this.planrecipe.serving_size = result.serving_size
          }
          this.$store.dispatch('getLatestOrder')
        })
      }
    },
    goToReplace () {
      this.$store.dispatch('save', { key: 'replaceme', value: this.planrecipe })
      this.$store.dispatch('goTo', 'discover')
    },

    markAsEaten () {
      // This functions finds and marks the recipe as eaten in the current plan.
      const toggledValue = !this.rplan.eaten
      this.planrecipe.eaten = toggledValue
      this.$emit('toggledEaten', { eaten: toggledValue })
      const details = {
        url: this.$store.state.api.plan.rp,
        id: this.rplan.id,
        method: 'PATCH',
        data: {
          eaten: toggledValue
        }
      }
      this.$store.dispatch('apiSend', details)
      this.$store.dispatch('logAnalyticsEvent', { name: 'marked_meal_as_eaten' })

      // Record recipe impression
      if (this.planrecipe && this.planrecipe.eaten) {
        const recipeId = this.planrecipe.recipe.id
        this.$store.dispatch('recordRecipeImpression', [
          { recipe: recipeId, flag: this.$store.state.recipeImpressionFlags.DELETED }
        ])
      }
    },

    async createNewEmptyMenu () {
      const details = {
        url: this.$store.state.api.plan.weeklyCreate,
        method: 'POST'
      }
      this.$store.dispatch('apiSend', details).then(async plan => {
        const details = {
          url: this.$store.state.api.shop.order,
          id: plan.order
        }
        await this.$store.dispatch('apiSend', details).then(async order => {
          if (order.hasOwnProperty('id')) {
            await this.$store.dispatch('save', { key: 'newplan', value: plan })
            await this.$store.dispatch('save', { key: 'neworder', value: order }).then(async () => {
              await this.$store.dispatch('refreshExistingOrder')
            })
          }
        })
      })
    },

    getTargetIndexForAddingRecipeToEndOfMenu () {
      let targetIndex = 0
      this.$store.getters.days.forEach(day => {
        if (day.position > targetIndex) targetIndex = day.position
      })
      targetIndex += 1
      return targetIndex
    },

    getDayIndexOfRecipeInNewPlan (planrecipe) {
      if (planrecipe) {
        return this.$store.getters.days.findIndex(d => d.planrecipes.some(p => p.recipe.id === planrecipe.recipe.id))
      }
    }, 

    checkWhetherToAddOrReplaceRecipe () {
      this.selectingThisRecipe = true
      if (this.$store.state.replaceme) {
        const replaceTargetIndex = this.getDayIndexOfRecipeInNewPlan(this.$store.state.replaceme)
        this.useThisRecipeToReplaceOtherRecipe(replaceTargetIndex)
      } else {
        this.addThisRecipeToEndOfMenu()
      }
    },

    async addThisRecipeToEndOfMenu () {
      // 1. If necessary, create a new empty menu.
      if (!this.$store.state.newplan) await this.createNewEmptyMenu()
      
      // 2. Get the position in which we want to add the recipe
      const targetIndex = this.getTargetIndexForAddingRecipeToEndOfMenu()

      // 3. Create a new empty day in the menu.
      const detailsDaily = {
        url: this.$store.state.api.plan.dailyCreate,
        method: 'POST',
        data: {
          multi_day_id: this.$store.state.newplan.id,
          position: targetIndex
        }
      }
      const newDaily = await this.$store.dispatch('apiSend', detailsDaily)

      // 4. Create a new recipesplan in the new day.
      //    Add this recipe to the recipesplan.
      const detailsRecipesPlan = {
        url: this.$store.state.api.plan.rpCreate,
        method: 'POST',
        data: {
          single_day_id: newDaily.id,
          recipe_id: this.planrecipe.recipe.id,
          serving_size: this.$store.state.profile.adults + 0.5 * this.$store.state.profile.children
        }
      }
      await this.$store.dispatch('apiSend', detailsRecipesPlan)

      // 5. Refresh the order and the plan
      this.selectingThisRecipe = null
      this.$store.dispatch('getLatestOrder')

      // 6. Track an event and record a recipe impression
      this.$store.dispatch('logAnalyticsEvent', { name: 'added_a_recipe' })
      this.$store.dispatch('recordRecipeImpression', [
        { recipe: this.planrecipe.recipe.id, flag: this.$store.state.recipeImpressionFlags.SELECTED }
      ])
    },

    async useThisRecipeToReplaceOtherRecipe (dayIndex) {
      // 1. Replace the recipe in the recipesplan
      const planRecipeDetails = {
        url: this.$store.state.api.plan.rp,
        id: this.$store.state.newplan.single_days[dayIndex].planrecipes[0].id,
        method: 'PATCH',
        data: { recipe_id: this.planrecipe.recipe.id }
      }
      await this.$store.dispatch('apiSend', planRecipeDetails)

      // 2. Refresh the order, the plan and navigate back
      this.selectingThisRecipe = null
      this.$store.dispatch('getLatestOrder')

      // 3. Empty the variable replacement variables, since the replacing happened now
      this.$store.dispatch('save', { key: 'replaceme', value: null })
      setTimeout(() => this.$router.go(-1), 1000) // timeout prevents iOS from crashing here

      // 4. Track en event and log recipe impressions
      this.$store.dispatch('logAnalyticsEvent', { name: 'replaced_a_recipe' })
      this.$store.dispatch('recordRecipeImpression', [
        { recipe: this.$store.state.newplan.single_days[dayIndex].planrecipes[0].recipe.id, flag: this.$store.state.recipeImpressionFlags.REPLACED },
        { recipe: this.planrecipe.recipe.id, flag: this.$store.state.recipeImpressionFlags.SELECTED }
      ])
    },

    async deleteThisRecipeFromMenu () {
      // 1. In the menu, find the single day, which contains the current recipe.
      const day = this.$store.getters.days.find(d => d.planrecipes.some(p => p.recipe.id === this.planrecipe.recipe.id))

      if (day && !this.$store.state.deletingRecipe) {
        this.deletingThisRecipe = true
        this.$store.state.deletingRecipe = true

        // 2. Remove it from the api by removing planrecipe and single day.
        const detailsPlanRecipe = {
          url: this.$store.state.api.plan.rp,
          id: day.planrecipes[0].id, // 'this.planrecipe' often has no id ...
          method: 'DELETE'
        }
        await this.$store.dispatch('apiSend', detailsPlanRecipe)
        
        const detailsSingleDay = {
          url: this.$store.state.api.plan.daily,
          id: day.id,
          method: 'DELETE'
        }
        await this.$store.dispatch('apiSend', detailsSingleDay)
        
        // 3. Refresh the order and the plan
        await this.$store.dispatch('getLatestOrder')
        this.$store.state.deletingRecipe = null
        this.deletingThisRecipe = null

        // 4. Record recipe impression
        this.$store.dispatch('recordRecipeImpression', [
          { recipe: this.planrecipe.recipe.id, flag: this.$store.state.recipeImpressionFlags.DELETED }
        ])
      }
    }
  }
 }
</script>

<style lang="scss" scoped>
@import './../../theme/main.scss';

.recipe-card {
  box-shadow: $box-shadow;
  margin: 20px;
  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: calc(min(80vw, 400px));
  max-width: 400px;
  background: white;
}

.recipe-image {
  z-index: 1;
  img {
    display: block;
    border-radius: $border-radius;
  }
  .recipe-bg-img {
    background: $col-font;
    padding-top: 67%;
  }
}

.recipe-info {
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  padding: 5px 15px 15px 15px;
  background: white;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 2;
}

.recipe-buttons {
  margin-top: 10px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
}


// Styling for eaten recipes

.bw {
  filter: grayscale(60%) contrast(80%) brightness(80%) opacity(70%);
}

// Styling for tags in the top area of the recipe card

.top-position {
  position: absolute;
  z-index: 2;
  border-radius: $border-radius;
  margin: 5px;
}


// Styling for buttons

.delete-button {
  width: 22px;
  height: 22px;
  --stroke: #{$col-gray-font};
  --stroke-width: 1.6rem;
  margin-top: -2px;
  margin-right: 3px;
}

.disabled-delete-button {
  opacity: .5;
}

.reloading-price-delta {
  --color: #{$col-light-gray-font};
  margin: -5px 5px;
}

.recipe-like {
  margin: -4px -12px 0 auto;
}

.spinner-select {
  --color: white;
  height: 22px;
  margin: -6px 0;
}

.min-width {
  min-width: 97px;
}

</style>
