export const constant = {
  'de': {
    'eat_later': 'Runterschieben',
    'ate_it': "Hab's\ gegessen",
    'not_eaten': 'Nicht gegessen',
    'replace': 'Ersetzen',
    'add_to_menu': "In den Warenkorb",
    'remove_from_menu': "Aus dem Warenkorb entfernen"
  }
}
